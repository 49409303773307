
import { ThingsFeatureEntityModel, ThingsFeatureQueryModel } from '@common-src/entity-model/things-feature-entity';
import { ICRUDQ } from '@common-src/model/interface';
import { get, post, put, del } from './request';
import { ThingsEntityModel } from '@common-src/entity-model/things-entity';

const URL_PATH = `${IOT_BASE_REQUEST_PATH}/things/feature`;

class ThingsFeatureService implements ICRUDQ<ThingsFeatureEntityModel, ThingsFeatureQueryModel> {
    async create(model: ThingsFeatureEntityModel):Promise<ThingsFeatureEntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<ThingsFeatureEntityModel> {
        const url = `${URL_PATH}/detail/${modelId}`;
        const res = await get(url);
        // return new ThingsFeatureEntityModel().toModel(res);
        return res;
    }

    async update(model: ThingsFeatureEntityModel):Promise<ThingsFeatureEntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: ThingsFeatureEntityModel):Promise<ThingsFeatureEntityModel> {
        const url = `${URL_PATH}/delete/${model.id}`;
        const res = await del(url);
        return res;
    }

    // async deleteDeviceFeature(model: {id: string}, deviceTypeId: string):Promise<any> {
    //     const url = `${BI_BASE_REQUEST_PATH}/deviceType/delFeature/${model.id}/${deviceTypeId}`;
    //     const res = await del(url);
    //     return res;
    // }

    async query(query?: ThingsFeatureQueryModel, page?: number, limit?: number):Promise<any> {
        const url = `${URL_PATH}/list`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new ThingsFeatureEntityModel().toModel(item));
        return res;
    }

    async getAll(selectFunctionList: Array<ThingsEntityModel>):Promise<any> {
        const url = `${URL_PATH}/getAll`;
        let res = await get(url);
        return _.map(
            _.filter(
                res,
                item => _.findIndex(selectFunctionList, functionItem => functionItem.isSame(item)) === -1
            ),
            item => {
                const id = item.id;
                return {
                    value: id,
                    name: `${_.get(item, 'name')} | ${_.get(item, 'identifier')}`,
                    check: false
                };
            });
    }
}

export default new ThingsFeatureService();
