import { ThingsTemplateEntityModel, ThingsTemplateQueryModel } from './things-template-entity';
import { GroupEntityType } from '@common-src/model/enum';
export class ThingsFeatureEntityModel extends ThingsTemplateEntityModel {
    constructor(groupId?: string) {
        super(groupId, GroupEntityType.FEATURE);
    }
}

export class ThingsFeatureQueryModel extends ThingsTemplateQueryModel {

}
